import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'what-input'
// eslint-disable-next-line import/no-extraneous-dependencies
import { loadableReady } from '@loadable/component'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { App, sentryHelpers } from '@vivialplatform/content-site-render-lib'

// eslint-disable-next-line import/no-unresolved
import jsonConfig from './config.json'
import './css/app.scss'

const { REACT_APP_ENV, REACT_APP_SENTRY_DSN, REACT_APP_CONTENT_SITE_CODE, NODE_ENV } = process.env
const isStaticPage = window && window.__INITIAL_DATA__

let data = {}
const config = { ...jsonConfig.layout_config, isStatic: false }

if (isStaticPage) {
  if (Object.hasOwnProperty.call(window.__INITIAL_DATA__, 'data')) {
    // This happens when you are on a static page, then the SPA loads over it
    data = window.__INITIAL_DATA__.data
  }

  if (Object.hasOwnProperty.call(window.__INITIAL_DATA__, 'config')) {
    config.isPreview = window.__INITIAL_DATA__.config.isPreview || false
  }
}

if (NODE_ENV === 'development') {
  const type = window.location.pathname.split('/').pop()
  if (['article', 'deal', 'event', 'profile'].includes(type)) {
    // eslint-disable-next-line global-require
    const getData = require(
      '@vivialplatform/content-site-render-lib/bin/getTestData.js'
    )
    data = getData().find((d) => d.model.type.toLowerCase() === type).model
    if (data.original_price) {
      data.original_price = data.original_price.toString()
    }
    if (data.discounted_price) {
      data.discounted_price = data.discounted_price.toString()
    }
    if (!data.conditions) {
      data.conditions = ''
    }
  } else {
    data = {
      type: 'PAGE_NOT_FOUND',
      name: '404'
    }
  }
}

if (REACT_APP_SENTRY_DSN) {
  sentryHelpers.initializeSentry(REACT_APP_SENTRY_DSN, REACT_APP_ENV, REACT_APP_CONTENT_SITE_CODE)
}

const component = (
  <BrowserRouter>
    <App data={data} config={config} />
  </BrowserRouter>
)

if (isStaticPage) {
  loadableReady(() => {
    hydrate(
      component,
      document.getElementById('app')
    )
  })
} else {
  render(
    component,
    document.getElementById('app')
  )
}
